<template>
  <v-dialog v-model="dialog" max-width="900">
    <v-card>
      <v-card-title>Детали {{ data.date }}</v-card-title>
      <v-card-text>
        <div>
          <v-simple-table dense small>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Наименование</th>
                <th class="text-left">Начало</th>
                <th class="text-left">Конец</th>
                <th class="text-left">Выполнено</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(el, i) in data.task_work" :key="i">
                <td class="text-left" :style="`font-weight: 500; height: 38px; `">{{ el.id }}</td>
                <td class="text-left" :style="`font-weight: 500; height: 38px`">{{ el.name }}</td>
                <td class="text-left" :style="`font-weight: 500; height: 38px`">{{ el.date_start }}</td>
                <td class="text-left" :style="`font-weight: 500; height: 38px; ` + (isLate(el) ? ` color: red` : ``)">{{ el.date_end }}</td>
                <td class="text-left" :style="`font-weight: 500; height: 38px`">{{ el.date_done }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="$emit('input')">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    data: Object,
    value: Boolean,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  watch: {},
  methods: {
    getDateDone(el) {
      if (el.date_done && el.date_done == this.data.date) return el.date_done;
      return null;
    },
    isLate(el) {
      if (new Date(el.date_end) < new Date(this.data.date)) return true;
      return false;
    },
  },
};
</script>
